import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <h1>NOT FOUND</h1>
    <p>You are not here.</p>
    <Link to="/">Maybe go back to the start?</Link>
  </Layout>
)

export default NotFoundPage
